
html, body
{
 height:100%;
 width:100%;
 font-family: 'Open Sans', sans-serif;
 margin:0;
 padding:0;
}

#mainWrapper
{   
    width:480px;
    min-height:300px;
	margin: 5% auto 0 auto;
	padding:0;
}

#logo
{
	width:200px;
	
}
.mainHeader
{
	text-align:center;
	font-size:3em;
	width:100%;
	margin:0;
}

#content
{
    width:100%;
	border-style:solid;
    border-width:1px;
	border-radius: 5px;
	position: relative;
	padding-top:2em;
	
}
#contentWrapper
{
	width:90%;
	height:100%;
	margin: auto auto;
}

#dataContainer
{
	margin-top:2em;
	border-style: solid;
	border-radius: 5px;
	border-color: black;
	border-width: 1px;
	padding:1em;
}
.formLabel
{
	width:10em;
	display: inline-block;
}

#contact
{
	text-align:center;
	bottom: 10px;
	width:100%;
}
#contact a
{
	text-decoration: none;
	width:100px;
	padding:0.2em 0.5em;
	font-size:1.5em;
}

#footer-logo
{
	width:250px;
	margin:5px auto 0 auto;
	padding:0;
	left:0px;
}

#copyright
{
	margin: 0 auto;
	padding:0;
	width: 100%;
	text-align:center;
	position:relative;
}